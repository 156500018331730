<template>
    <b-form-checkbox class="mb-2 text-white" v-model="value">
        {{item.text[LANG_INDEX]}}
    </b-form-checkbox>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            value: false,
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),        
    },
    props: {
       item: {
        type: Object,
       },
       sending: {
        type: Boolean,
        default: false,
       }
    },
    watch: {
        value: function(val){
            let data = {name:this.item.inputName, val:val}
            this.$emit('dataChange', data)
        }
    }  
}
</script>

<style lang="sass">
.custom-control-label
    color: #F2F2F2 !important
    font-weight: 500 !important
</style>