<template>
  <b-container class="px-4 px-lg-2 px-xl-5 py-5 pb-lg-0">
    <b-row>
      <!-- левый кол -->
      <b-col class="col-12 col-xl-6 text-white">
        <AnimationFrame v-bind:componentName="'ContactText'"></AnimationFrame>
      </b-col>
      <!-- правый кол -->
      <b-col class="col-12 col-xl-6 text-white">
        <AnimationFrame v-bind:componentName="'ContactForm'"></AnimationFrame>
      </b-col>
    </b-row>
  </b-container>
  </template>
  
  <script>

  // import ContactMessage from '../components/contact/ContactMessage.vue'
  import ContactForm from '../components/contact/ContactForm.vue' // eslint-disable-line
  import AnimationFrame from '@/components/general/AnimationFrame.vue';
    export default {
    components: {
      // ContactMessage,
      ContactForm, // eslint-disable-line
      AnimationFrame, // eslint-disable-line
    },
  }
  </script>