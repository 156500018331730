<template>
    <div>
      <!-- <HeaderMedia></HeaderMedia>       -->
      <AnimationFrame v-bind:componentName="'HeaderMedia'"></AnimationFrame>
      <!-- <AdvantageMedia></AdvantageMedia> -->
      <AnimationFrame v-bind:componentName="'AdvantageMedia'"></AnimationFrame>
      <!-- <OurResults></OurResults> -->
      <AnimationFrame v-bind:componentName="'OurResults'"></AnimationFrame>
      <!-- <OurSources></OurSources> -->
      <AnimationFrame v-bind:componentName="'OurSources'"></AnimationFrame>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import AdvantageMedia from '../components/mediabuying/AdvantageMedia.vue'
  import HeaderMedia from '../components/mediabuying/HeaderMedia.vue'
  import OurSources from '../components/mediabuying/OurSources.vue'
  import OurResults from '../components/mediabuying/OurResults.vue'
  import AnimationFrame from '@/components/general/AnimationFrame.vue'
  

  export default {
    components: {
      AdvantageMedia, // eslint-disable-line
      HeaderMedia, // eslint-disable-line
      OurSources, // eslint-disable-line
      OurResults, // eslint-disable-line
      AnimationFrame, // eslint-disable-line
    },    
    data(){
      return {
  
  
      }
    },
    computed: {
      ...mapGetters(['LANG_INDEX'])
    },
    // name: 'HomeView',
  }
  </script>