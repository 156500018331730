<template>
    <div>
      <!-- <HeaderInapp></HeaderInapp> -->
      <AnimationFrame v-bind:componentName="'HeaderInapp'"></AnimationFrame>
      <!-- <OurPluses></OurPluses> -->
      <AnimationFrame v-bind:componentName="'OurPluses'"></AnimationFrame>
      <!-- <OurGeos></OurGeos> -->
      <AnimationFrame v-bind:componentName="'OurGeos'"></AnimationFrame>
      <!-- <OurPartners></OurPartners> -->
      <AnimationFrame v-bind:componentName="'OurPartners'"></AnimationFrame>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import HeaderInapp from '../components/inapp/HeaderInapp.vue'
  import OurPluses from '../components/inapp/OurPluses.vue'
  import OurGeos from '../components/inapp/OurGeos.vue'
  import OurPartners from '../components/inapp/OurPartners.vue'
  import AnimationFrame from '@/components/general/AnimationFrame.vue'
  export default {
    components: {
      HeaderInapp, // eslint-disable-line
      OurPluses, // eslint-disable-line
      OurGeos, // eslint-disable-line
      OurPartners, // eslint-disable-line
      AnimationFrame, // eslint-disable-line
    },    
    data(){
      return {
  
  
      }
    },
    computed: {
      ...mapGetters(['LANG_INDEX'])
    },
  }
  </script>