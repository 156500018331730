<template>
    <b-container class="d-flex justify-content-center">
        <b-img :src="teamPhoto"></b-img>
    </b-container>

</template>

<script>

import utils from '../../functions/utils'
export default {
    computed: {
        teamPhoto: function(){
            return utils.imageRelToAbs('/images/team/all.png')
        }
    }
}
</script>

<style scoped lang="sass">
img
    width: 100%
    height: auto
@media (min-width: 768px)    
    div
        margin-top: -16px    
</style>