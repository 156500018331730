<template>
    <div :class="componentClass" :style="componentStyle" @click="itemClick">

         <!-- -- текст по клику--- -->
         <section class="position-absolute bg-white p-3 d-flex justify-content-center align-items-center">
            <span class="text-center">
                {{ item.text[LANG_INDEX] }}
            </span>
          </section>

        <!-- ---видимая часть-- -->
         <div class="d-flex justify-content-between bg-white p-3 h-100 position-relative" :style="visiblePartStyle">
            <h4 :class="headerClass">
                {{ item.header[LANG_INDEX] }}
            </h4>
            <div :class="imageContainerClass">
                <b-img :src="item.image"></b-img>
            </div>
         </div>
    </div>    
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            clicked: false
        }
    },
    props: ['item', 'index'],
    computed: {
        ...mapGetters(['SIZE_IS', 'LANG_INDEX']),
        componentClass: function(){
            const fluidCondition = ([5,6].includes(this.SIZE_IS))
            const classBase = 'position-relative bg-white app-text-violet overflow-hidden mt-3'
            let rounded = 'app-rounded'
            rounded = ([0,3].includes(this.index) && fluidCondition) ? 'app-rounded-right' : rounded
            rounded = ([2,5].includes(this.index) && fluidCondition) ? 'app-rounded-left' : rounded
            return `${classBase} ${rounded}`
        },
        imageContainerClass: function(){
            const fluidCondition = ([5,6].includes(this.SIZE_IS))
            const classBase = 'd-flex justify-content-center h-100'
            let alignItems = 'align-items-center'
            alignItems = ([0,1,5].includes(this.index) && fluidCondition) ? 'align-items-start' : alignItems
            alignItems = ([2,3,4].includes(this.index) && fluidCondition) ? 'align-items-end' : alignItems
            return `${classBase} ${alignItems}`
        },
        componentStyle: function(){
            const fluidCondition = ([5,6,7].includes(this.SIZE_IS))
            const height = '172px'
            //на мелких во всю ширину
            let width = '100%'
            //на таблетках типа рандомная псевдополовина
            width = ([3,4].includes(this.SIZE_IS) && [0, 3, 4].includes(this.index) ) ? 'calc(55% - 8px)' : width
            width = ([3,4].includes(this.SIZE_IS) && [1, 2, 5].includes(this.index) ) ? 'calc(45% - 8px)' : width

            //на флюидных первый ряд
            width = ([0,1,2].includes(this.index) && fluidCondition) ? 'calc(33.3% - 8px)' : width
            //на флюидных второй ряд
            width = ([3].includes(this.index) && fluidCondition) ? 'calc(40% - 8px)' : width
            width = ([4,5].includes(this.index) && fluidCondition) ? 'calc(30% - 8px)' : width
            return `width:${width}; height: ${height}`
            //на больших нефлюидных
        },
        headerClass: function(){
            const classBase = 'my-0 d-flex font-weight-bold pr-2'
            const fluidCondition = ([5,6,7].includes(this.SIZE_IS))
            let aligment = 'align-items-center'
            aligment = ([0, 2, 4].includes(this.index) && fluidCondition) ? 'align-items-start' : aligment
            aligment = ([1, 3, 5].includes(this.index) && fluidCondition) ? 'align-items-end' : aligment
            return `${classBase} ${aligment}`
        },
        visiblePartStyle: function(){
            const styleBase = 'transition: all 0.2s'
            const opacity = (this.clicked) ? 'opacity: 0' : 'opacity: 1'
            return `${styleBase}; ${opacity}; `
        }
    },
    methods: {
        itemClick: function(){
            console.log('itemClick')
            this.clicked = true
        }
    }
}
</script>

<style scoped lang="sass">
div
    cursor: pointer
img
    width: 120px
    height: auto
section
    top: 0
    bottom: 0
    left: 0
    right: 0
    // z-index: -1    
span
    font-size: 19px    
@media (min-width: 768px)    
    img
        width: 110px
@media (min-width: 992px)    
    img
        width: 120px        
</style>