<template>
    <div :class="blockClass">
        <div class="position-relative ml-3">
            <h4 class="font-weight-bold">
                {{ item.name[LANG_INDEX] }}
            </h4>
            <div class="position-absolute" style="top:0; bottom: 0; left: -48px;">
                <!-- style="color:#b4fb35;" -->
                <b-icon icon="circle-fill" font-scale="2" class="mr-3 app-text-violet"></b-icon>    
            </div>
        </div>
        <p class="mt-3 mb-0">
            {{ item.text[LANG_INDEX] }}
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['item', 'placement'],
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        blockClass: function(){
            const fluidCondition = ([5,6].includes(this.SIZE_IS))
            const classBase = 'd-flex flex-column py-4 px-5 bg-white app-text-violet mb-3'
            let rounded = 'app-rounded'
            rounded = (fluidCondition && this.placement === 'left') ? 'app-rounded-right' : rounded
            rounded = (fluidCondition && this.placement === 'right') ? 'app-rounded-left' : rounded
            return `${classBase} ${rounded}`
        }
    },
}
</script>

<style scoped lang="sass">
h4
    font-size: 16px
p
    font-size: 14px
@media (min-width: 1024px)    
    p
        font-size: 16px    
@media (min-width: 1366px)
    h4, p
        font-size: 24px
</style>
