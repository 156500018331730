<template>
    <div>
        <h1 class="my-5 font-italic app-header_medium font-weight-bold">
          {{ header[LANG_INDEX] }}
        </h1>
        <p class="pr-5 pr-lg-0">
          {{ text[LANG_INDEX] }}
        </p>
        <a href="mailto:sales@zumoads.com" class="d-block font-weight-bold mb-3 mb-sm-5 mt-lg-5 text-white">sales@zumoads.com</a>
        <SocialMedia></SocialMedia>
    </div>
</template>

<script>
import SocialMedia from './SocialMedia.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        SocialMedia
    },
    data(){
      return {
        header: [
          'Contact us to make profit',
          'Свяжитесь с нами для получения прибыли'
        ],
        text: [
          'If you want to get more information about us, fill out the form to contact us or reach out in any way that’s convenient for you',
          'Если вы хотите получить больше информации о нас, заполните форму, чтобы связаться с нами'
        ]
  
      }
    },
    computed: {
      ...mapGetters(['LANG_INDEX'])
    },
}
</script>

<style scoped lang="sass">
h1
  font-size: 24px
p
  font-size: 18px  
a
  font-size: 28px  
@media (min-width: 768px)  
  h1
    font-size: 36px
  a
    font-size: 23px
@media (min-width: 1024px)    
  a
    font-size: 28px
</style>