<template>
    <div class="d-flex justify-content-center align-items-center app-rounded app-bg-violet mb-3 p-2 pb">
        <b-img :src="item"></b-img>
    </div>
</template>

<script>
export default {
    props: ['item']
}
</script>

<style scoped lang="sass">
div
    width: calc(50% - 8px)
    // width: 100%
img
    width: 100%
    height: auto    
    max-width: 100px
@media (min-width: 768px)
    div
        width: calc(25% - 8px)
@media (min-width: 992px)
    div
        width: calc(25% - 16px)
    img
        max-width: 150px
@media (min-width: 1200px)        
    img
        max-width: 180px
</style>