<template>
    <div id="bg-animation">

    </div>
</template>


<script>
import utils from '../../functions/utils'
export default {
    data(){
        return {
            loadCounter: 0,
        }
    },
    methods: {
        cdnLoad: function(){
            const vueContext = this
            const path1 = utils.scriptRelToAbs('/thirdparty/three.min.js')
            const path2 = utils.scriptRelToAbs('/thirdparty/vanta.fog.min.js')
            const a = document.createElement('script')
            a.src = path1
            a.onload = function(){
                vueContext.loadCounter = vueContext.loadCounter + 1
            }
            document.head.appendChild(a)
            const b = document.createElement('script')
            b.src = path2
            b.onload = function(){
                vueContext.loadCounter = vueContext.loadCounter + 1
            }            
            document.head.appendChild(b)
        },
        animationStart: function(){
            window.VANTA.FOG({
                el: "#bg-animation",
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                highlightColor: 0x0,
                midtoneColor: 0x221b3c,
                lowlightColor: 0x5d46eb,
                baseColor: 0x562287,
                blurFactor: 0.55,
                speed: 2.40,
                zoom: 0.30
            })            
        }
    },
    mounted: function(){
        this.cdnLoad()
    },
    watch: {
        loadCounter: function(){
            console.log('this.loadCounter', this.loadCounter)
            if (this.loadCounter === 2){
                console.log(window.VANTA)
                const vueContext = this
                window.setTimeout(function(){
                    vueContext.animationStart()
                }, 6000)
                // this.animationStart()
            }
        }
    }
}
</script>
