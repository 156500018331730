<template>
    <section>
        <iframe :src="irfameSrc" width="100%" height="100%"></iframe>
        <div></div>
    </section>
</template>


<script>
import utils from '../../functions/utils'
export default {
    computed: {
        irfameSrc: function(){
            return utils.imageRelToAbs('/bg/')
        }
    }
}
</script>

<style scoped lang="sass">
section
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: -1      
iframe, div    
    border: none !important    
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0  
div
    background: linear-gradient(135deg, rgba(0,0,0,1) 0%,rgba(125,185,232,0) 100%)
    // z-index: 0    
</style>