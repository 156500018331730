<template>
    <b-container>
        <b-row>
            <b-col>
                <h3 class="font-weight-bold mt-5 mb-3">
                    {{ header[LANG_INDEX] }}
                </h3>
                <h5>
                    {{ subHeader[LANG_INDEX] }}
                </h5>
                <div class="d-flex justify-content-center align-items-center">
                    <b-img :src="image"></b-img>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import utils from '../../functions/utils.js'
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            header: ['GEOs', 'Географии'],
            subHeader: ['worldwide', 'по всему миру'],
            image: utils.imageRelToAbs('/images/icon/map.png')
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
h3
    font-size: 24px
img
    width: 100%
    height: auto    
@media (min-width: 768px)    
    h3
        font-size: 36px    
</style>