<template>
    <b-container>
      <b-row>
        <b-col>
          <!-- pb-md-5 -->
          <h1 class="mt-5 mb-5 mb-md-5 text-center font-weight-bold">
            {{ header[LANG_INDEX] }}
          </h1>
          <div class="d-flex flex-column pt-0 pt-xl-5">
            <!-- <CasesTwoo v-bind:dataObj="items[0]"></CasesTwoo>       -->
            <AnimationFrame v-bind:componentName="'CasesTwoo'" v-bind:dataObj="items[0]"></AnimationFrame>
            <!-- <CasesThree v-bind:dataObj="items[1]"></CasesThree> -->
            <AnimationFrame v-bind:componentName="'CasesThree'" v-bind:dataObj="items[1]"></AnimationFrame>
            <!-- <CasesThree v-bind:dataObj="items[2]"></CasesThree> -->
            <AnimationFrame v-bind:componentName="'CasesThree'" v-bind:dataObj="items[2]"></AnimationFrame>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  import utils from '@/functions/utils';
  import { mapGetters } from 'vuex'
  import CasesTwoo from '../components/cases/CasesTwoo.vue'
  import CasesThree from '../components/cases/CasesThree.vue'
  import AnimationFrame from '@/components/general/AnimationFrame.vue';
  export default {
    components: {
      AnimationFrame,  // eslint-disable-line
      CasesTwoo,  // eslint-disable-line
      CasesThree,  // eslint-disable-line 
    },    
    data(){
      return {
        header: [
          'Our results',
          'Наши кейсы'
        ],
        items: [
          //первый
          {
            image: utils.imageRelToAbs('/images/cases/patpat.png'),
            values: [
              {
                header: '>7000',
                text: ['purchases/month', 'покупок/месяц']
              },
              {
                header: '>200000$',
                text: ['from purchases', 'от покупок']
              },              
            ]
          },
          //второй
          {
            image: utils.imageRelToAbs('/images/cases/exness.png'),
            values: [
              {
                header: '>15000$',
                text: ['first time deposit amount', 'сумма первых депозитов']
              },
              {
                header: '>12',
                text: ['active geos', 'активныx гео']
              },              
              {
                header: '70%',
                text: ['users with re-deposiits', '70% пользователей с повторными депозитами']
              },                            
            ]
          }, 
          //третий   
          {
            image: utils.imageRelToAbs('/images/cases/olimpbet.png'),
            values: [
              {
                header: '28%',
                text: ['Mintegral CR reg to deposit', 'Mintegral CR из регистрации в депозит']
              },
              {
                header: '27%',
                text: ['Iron Source CR reg to deposit', 'Iron Source CR регистрации в депозит']
              },              
              {
                header: '500FTDs',
                text: ['per month', 'в месяц']
              },                            
            ]
          },                
        ]
  
      }
    },
    computed: {
      ...mapGetters(['LANG_INDEX'])
    },
  }
  </script>

<style lang="sass" scoped>
@media (min-width: 768px)
  h1
    font-size: 64px
</style>