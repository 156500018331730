<template>
    <b-link :to="href" :class="linkClass">
        {{ anchor }}
    </b-link>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: ['item', 'isFooter', 'isMobile'],
    computed: {
        ...mapGetters(['LANG_INDEX']),
        href: function(){
            return this.item.href
        },
        anchor: function(){
            return this.item.anchor[this.LANG_INDEX]
        },
        linkClass: function(){
            const classBase = 'font-weight-bold flex-shrink-0 pr-lg-4'
            const textColor = (this.isMobile) ? 'text-white' : 'app-text-violet'
            const mY = (this.isFooter) ? 'my-3' : ''
            return `${classBase} ${mY} ${textColor}`
        }
    },
    mounted: function(){
        // console.log('this.LANG_INDEX', this.LANG_INDEX)
    }
}
</script>

<style scoped lang="sass">
a
    font-size: 20px
@media (min-width: 992px)
    a

</style>