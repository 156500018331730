<template>
    <b-container class="mt-5">
        <b-row>
            <!-- class="mb-5 mb-md-0" -->
            <b-col cols="12" lg="6">
                <AnimationFrame v-bind:componentName="'ClientPartnersCol'" v-bind:dataObj="clients"></AnimationFrame>
            </b-col>
            <b-col cols="12" lg="6">
                <AnimationFrame v-bind:componentName="'ClientPartnersCol'" v-bind:dataObj="partners"></AnimationFrame>
            </b-col>
        </b-row>
    </b-container>
</template>


<script>

import utils from '../../functions/utils' // eslint-disable-line
import AnimationFrame from '../general/AnimationFrame.vue';

export default {
    components: {
        AnimationFrame
    },
    data(){
        return {
            clients: {
                items: [
                    utils.imageRelToAbs('/images/small/wink.png'),
                    utils.imageRelToAbs('/images/small/patpat.png'),
                    utils.imageRelToAbs('/images/small/entain.png'),
                    utils.imageRelToAbs('/images/small/studybay.png'),
                    utils.imageRelToAbs('/images/small/bwin.png'),
                    utils.imageRelToAbs('/images/small/fonbet.png'),
                    utils.imageRelToAbs('/images/small/888.png'),
                    utils.imageRelToAbs('/images/small/mamba.png'),
                ],
                header: [
                    'Our clients',
                    'Наши клиенты',
                ],
            },
            partners: {
                items: [
                    utils.imageRelToAbs('/images/small/shareit.png'),
                    utils.imageRelToAbs('/images/small/mintegral.png'),
                    utils.imageRelToAbs('/images/small/pokkt.png'),
                    utils.imageRelToAbs('/images/small/affle.png'),
                    utils.imageRelToAbs('/images/small/tapjoy.png'),
                    utils.imageRelToAbs('/images/small/mytarget.png'),
                    utils.imageRelToAbs('/images/small/inmobi.png'),
                    utils.imageRelToAbs('/images/small/baidu.png'),
                ],
                header: [
                    'Our partners',
                    'Наши партнеры',
                ], 
            },            
        }
    },
}
</script>

