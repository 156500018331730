<template>
        <div :class="blockWrapClass">
            <span :class="headerClass">
                {{ dataObj.header[this.LANG_INDEX] }}
            </span>
            <div class="d-flex justify-content-between flex-wrap">
                <ClientPartnersItem v-for="(item, index) in dataObj.items"
                    v-bind:key="index"
                    v-bind:item="item"
                >
                </ClientPartnersItem>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex' // eslint-disable-line
import ClientPartnersItem from './ClientPartnersItem.vue'
export default {
    components: {
        ClientPartnersItem
    },
    data(){
        return {
            blockWrapClass: 'bg-white app-rounded p-3 p-lg-4 mt-3 mt-md-5 mt-xl-0 mb-4 mb-lg-5 text-center',
            headerClass: 'd-block app-text-violet font-weight-bold mb-3',
        }
    },
    props: ['dataObj'],
    computed: {
        ...mapGetters(['LANG_INDEX']),
    },
    mounted: function(){
        // console.log('col mounted')
    }
}
</script>

<style scoped lang="sass">
span
    font-size: 24px
@media (min-width: 768px)    
    span
        font-size: 36px
</style>