<template>
    <div>
      <!-- <TeamSlider></TeamSlider> -->
      <AnimationFrame v-bind:componentName="'TeamSlider'"></AnimationFrame>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import TeamSlider from '../components/team/TeamSlider.vue'
  import AnimationFrame from '@/components/general/AnimationFrame.vue';
  export default {
    components: {
      TeamSlider, // eslint-disable-line
      AnimationFrame, // eslint-disable-line
    },    
    data(){
      return {
  
  
      }
    },
    computed: {
      ...mapGetters(['LANG_INDEX'])
    },
  }
  </script>