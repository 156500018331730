<template>
    <div class="app-form position-relative d-flex flex-column py-5 px-4 mb-5 mt-5 mt-xl-0 overflow-hidden">
        <transition name="disappear">
            <b-alert v-if="thanksMessage.state"
                :show="true" 
                variant="success" 
                class="app-thanks position-absolute d-flex flex-column justify-content-center align-items-center px-1 px-lg-5 mb-0" 
            >
            <p class="mb-5 text-center">
                {{thanksMessage.text[LANG_INDEX]}}
            </p>
            <b-button variant="outline-success" class="rounded-pill" @click="thanksClose">
                Close
            </b-button>
        </b-alert>
        </transition>
        <FormInput v-bind:item="fields[0]" v-bind:sending="sending" v-on:dataChange="inputChange"></FormInput>
        <FormInput v-bind:item="fields[1]" v-bind:sending="sending" v-on:dataChange="inputChange"></FormInput>
        <FormInput v-bind:item="fields[2]" v-bind:sending="sending" v-on:dataChange="inputChange"></FormInput>
        <FormInput v-bind:item="fields[3]" v-bind:sending="sending" v-on:dataChange="inputChange"></FormInput>

        <FormCheckbox v-bind:item="checkboxes[0]" v-bind:sending="sending" v-on:dataChange="checkboxChange" class="mt-2"></FormCheckbox>
        <!-- <FormCheckbox v-bind:item="checkboxes[1]" v-bind:sending="sending" v-on:dataChange="checkboxChange" class="text-secondary"></FormCheckbox> -->
        <b-button size="lg" class="rounded-pill px-2 py-3 mt-3 align-self-end" @click="trySend" variant="success" :disabled="buttonDisable">
               {{ buttonText[LANG_INDEX] }}
        </b-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormInput from './FormInput.vue'
import FormCheckbox from './FormCheckbox.vue'
export default {
    components: {
        FormInput,
        FormCheckbox
    },
    data(){
        return {
            fields: [
                {
                    inputName: 'name', 
                    val: '', 
                    required: true,                    
                    isCorrect: false, 
                    placeholder: [
                        'Name',
                        'Имя',
                    ],
                    type: 'input' 
                },
                {
                    inputName: 'companyName', 
                    val: '', 
                    required: false,
                    isCorrect: false, 
                    placeholder: [
                        'Company name', 
                        'Компания', 
                    ],
                    type: 'input'                     
                },
                {
                    inputName: 'email', 
                    val: '', 
                    required: true,                                        
                    isCorrect: false, 
                    placeholder: [
                        'Email',
                        'Электронная почта'
                    ],
                    type: 'input'                     
                },
                {
                    inputName: 'message',
                    val: '', 
                    required: true,                                        
                    isCorrect: false, 
                    placeholder: [
                        'Message', 
                        'Сообщение', 
                    ],
                    type: 'textarea'                     
                },
            ],
            sending: false,
            checkboxes: [
                {
                    inputName: 'personal', 
                    val: false, 
                    required: true, 
                    text: [
                        'I consent to the processing of my personal data for marketing purposes in accordance with the privacy policy',
                        'Я даю согласие на обработку моих персональных данных в маркетинговых целях в соответствии с политикой конфиденциальности.'
                    ]
                },
            ],
            thanksMessage: {
                state: false,
                text: [
                    'Thank you for contacting us! Our managers will get back to you soon',
                    'Спасибо за обращение. Наши менеджеры свяжутся с вами в ближайшее время',
                ],
            },
            buttonText: [
                'Send Message',
                'Отправить'
            ]            
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
        buttonDisable: function(){
            let res = false
            for (let i=0; i<this.fields.length; i ++){
                if (this.fields[i].required && !this.fields[i].isCorrect){
                    res = true
                    break
                }
            }
            for (let i=0; i<this.checkboxes.length; i ++){
                if (this.checkboxes[i].required && !this.checkboxes[i].val){
                    res = true
                    break
                }
            }            
            return res
        },
    },    
    methods: {
        fieldsClear: function(){
            console.log('fieldsClear')
            for (let i=0; i<this.fields.length; i++){
                console.log('iteration', i)
                this.fields[i].val = ''
            }
            const newArr = [...this.fields]
            this.fields = []
            this.fields = newArr
        },
        inputChange: function(data){
            let oldFields = this.fields
            for (let i=0; i < this.fields.length; i++){
                if (this.fields[i]['inputName'] === data.name){
                    if (data.name === 'email'){
                        this.fields[i].isCorrect = (data.val.match(/.+@.+\..+/i) && data.val.match(/.*@.*\..*/i)[0]) ? true : false
                    }else{
                        this.fields[i].isCorrect = (data.val != '') ? true : false
                    }
                    //здесь надо обновить this.fields
                    this.fields[i].val = data.val
                    break
                }
            }
            this.fields = []
            this.fields = oldFields
        },
        checkboxChange: function(data){
            for (let i=0; i < this.checkboxes.length; i++){
                this.checkboxes[i].val = (this.checkboxes[i]['inputName'] === data.name && data.val) ? true : false
            }
        },
        trySend: async function(){
            this.sending = true
            //проверяем филды - если везде isCorrect при qequired===true - отправляем
            let fieldsReady = true
            for (let i = 0; i < this.fields.length; i++){
                if (this.fields[i].required && !this.fields[i].isCorrect){
                    fieldsReady = false
                    // console.log('не все необходимые поля корректно заполнены')
                    break
                }
            }
            const checkboxesReady = (this.checkboxes[0].required && !this.checkboxes[0].val) ? false : true
            //если заполнено все что должно быть заполнено, отправляем данные
            if (fieldsReady && checkboxesReady){
                const name = `name=${this.fields[0].val}&`
                const companyName = (this.fields[1].val) ? `companyName=${this.fields[1].val}&` : ''
                const email = `email=${this.fields[2].val}&` 
                const message = `message=${this.fields[3].val}&`
                // const subscribe = `subscribe=${this.checkboxes[1].val}`
                const urlBase = 'https://zumoads.com/php/sendmail.php?'
                let url = urlBase + name + companyName + email + message // + subscribe
                url = encodeURI(url)
                //отправляем
                let response = await fetch(url, {method: 'GET'})
                if (response.ok){
                    let row_data = await response.text()
                    if (row_data === '1'){
                        this.thanksMessage.state =  true
                        //убираем thank message через 2 секунды
                        const vueContext = this
                        window.setTimeout(function(){
                            vueContext.thanksClose()
                        }, 2000)
                        //отправляем автогенеренное сообщение пользователю
                        const autoSendUrl = encodeURI('https://zumoads.com/php/autosend.php?'+email)
                        await fetch(autoSendUrl)
                    }
                }
            }
        },
        thanksClose: function(){
            this.thanksMessage.state = false
        },
    },
    watch: {
        fields: function(){
            // console.log('###############', this.fields)
        }
    }    
}
</script>
<style lang="sass">
.app-form
    background: #52378D
    // background: rgba(34, 23, 58, 0.6)
    border-radius: 30px
.app-thanks
    top: 0
    left: 0    
    right: 0
    bottom: 0
    z-index: 10
//исчезновение thank-message    
.disappear-leave
    opacity: 1
.disappear-leave-to
    opacity: 0    
.disappear-leave-active
    transition: opacity 0.3s ease-in    
</style>








<style lang="sass" scoped>
button
    font-size: 16px
    width: 70%
@media (min-width: 1024px)    
    button
        font-size: 24px    
        width: 50%
p
    font-size: 1.2rem        
</style>