<template>
    <!-- <div :class="animationClass" style="transition: all 0.3s ease-out" v-b-visible.once="animationStart"> -->
        <b-container>
            <b-row>
                <b-col>
                    <section :class="sectionClass">
                        <span class="font-weight-bold mb-3 mb-md-4 mt-0 mt-md-4">
                            {{ header[LANG_INDEX] }}
                        </span>
                        <i class="mb-3">
                            {{ text[LANG_INDEX] }}
                        </i>
                        <div class="d-flex justify-content-between flex-wrap">
                            <MarketingAgencyItem v-for="(item, index) in items"
                            v-bind:key="index"
                            v-bind:item="item"
                            ></MarketingAgencyItem>
                        </div>
                    </section>
                </b-col>
            </b-row>
        </b-container>
    <!-- </div> -->
</template>

<script>
import {mapGetters} from 'vuex'
import utils from '../../functions/utils' // eslint-disable-line
import MarketingAgencyItem from './MarketingAgencyItem.vue' // eslint-disable-line
export default {
    components: {
        MarketingAgencyItem // eslint-disable-line
    },
    data(){
        return {
            isVisible: undefined,
            header: [
                'Technological mobile marketing agency',
                'Технологическое агентство мобильного маркетинга'
            ],
            text: [
                '5 years of market expertise helps us dive into the project to develop and implement a comprehensive strategy for you to attract new users',
                '5 лет опыта работы на рынке помогают нам погрузиться в проект по разработке и реализации комплексной стратегии для привлечения новых пользователей'
            ],
            items: [
                {
                    value: '100+',
                    name: [
                        'clients',
                        'клиентов',
                    ],
                    image: '',
                },
                {
                    value: '150+',
                    name: [
                        'partners',
                        'партнеров',
                    ],
                    image: '',
                },
                {
                    value: '',
                    name: [
                        'mobile experts',
                        'мобильных экспертов',
                    ],
                    image: utils.imageRelToAbs('/images/icon/infinity.svg'),
                },                                
            ]
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        sectionClass: function(){
            const classBase = 'd-flex flex-column bg-white app-text-violet py-3 py-lg-4 px-4 px-lg-5 mt-4'
            const borderRadius = (this.SIZE_IS <= 2) ? 'app-rounded-top' : 'app-rounded'
            return `${classBase} ${borderRadius}`
        },
        animationClass: function(){
            return (this.isVisible) ? '' : 'pt-5'
        }
    },
    methods: {
        animationStart: function(visible){
            this.isVisible = visible
        }
    }
}
</script>

<style scoped lang="sass">
span
    font-size: 24px
i    
    font-size: 20px
@media (min-width: 768px)    
    span
        font-size: 32px    
    i    
        font-size: 24px
</style>
