<template>
    <div class="app-social d-flex justify-content-between justify-content-md-start align-item-center">
        <span v-for="(item, index) in socialMedia"
            v-bind:key="index"
            class="mr-2 mr-md-3"
        >
            <b-link :href="item.link">
                <img :src="item.image">
            </b-link>
        </span>
    </div>                            
</template>


<script>
import utils from '../../functions/utils'
// import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            socialMedia: [
                {
                    name: 'instagram',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/instagram.png'),
                    image1: utils.imageRelToAbs('/images/icon/instagram1.png')
                },
                {
                    name: 'telegram',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/telegram.png'),
                    image1: utils.imageRelToAbs('/images/icon/telegram1.png')
                },
                {
                    name: 'tiktok',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/tiktok.png'),
                    image1: utils.imageRelToAbs('/images/icon/tiktok1.png')
                },                        
                {
                    name: 'linkedin',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/linkedin.png'),
                    image1: utils.imageRelToAbs('/images/icon/linkedin1.png')
                },            
            ],
        }
    },
    computed: {
        // ...mapGetters(['SOCIAL_MEDIA'])
    }
}
</script>

<style lang="sass" scoped>
span
    width: 64px
    height: 64px 
img
    width: 100%
    height: auto
a
    width: 41px
    height: 41px    
@media (min-width: 1024px)
    a
        width: 72px
        height: 72px
</style>