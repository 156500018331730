<template>
    <b-container>
        <b-row>
            <b-col>
                <section class="d-flex flex-column text-white px-4 px-lg-5 pt-5">
                    <i>
                        {{ textPart1[LANG_INDEX] }}
                    </i>
                    <b>
                        {{ textPart2[LANG_INDEX] }}
                    </b>
                    <i class="align-self-end">
                        {{ textPart3[LANG_INDEX] }}
                    </i>
                    <span class="align-self-end">
                        <i>
                            {{ textPart4[LANG_INDEX] }}
                        </i>            
                        <b>
                            {{ textPart5[LANG_INDEX] }}
                        </b>
                    </span>
                </section>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            textPart1: [
                'Agency that boosts apps',
                'Агентство, которое повышает узнаваемость и прибыль вашего приложения,',
            ],
            textPart2: [
                'like no one else',
                'как никто другой',
            ],
            textPart3: [
                'We help businesses',
                'Мы помогаем компаниям',
            ],                        
            textPart4: [
                'to find',
                'находить',
            ],                                    
            textPart5: [
                'new customers',
                'правильных пользователей',
            ],                                                
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
section
    font-size: 24px
@media (min-width: 768px)    
    section
        font-size: 40px
</style>