import { render, staticRenderFns } from "./FooterLine.vue?vue&type=template&id=4d2bc7a1&scoped=true"
import script from "./FooterLine.vue?vue&type=script&lang=js"
export * from "./FooterLine.vue?vue&type=script&lang=js"
import style0 from "./FooterLine.vue?vue&type=style&index=0&id=4d2bc7a1&prod&lang=sass"
import style1 from "./FooterLine.vue?vue&type=style&index=1&id=4d2bc7a1&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d2bc7a1",
  null
  
)

export default component.exports