<template>
    <section>
        <b-container>
            <b-row>
                <b-col>
                    <h1 class="my-5 text-center font-weight-bold">
                        {{ header[LANG_INDEX] }}
                    </h1>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-row>
                <!-- левый контрол -->
                <b-col lg="1" class="d-none d-lg-block">
                    <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                        <b-button 
                            class="d-flex justify-content-center align-items-center bg-transparent border-0"
                            :disabled="controlLeftDisable" 
                            @click="leftControl"
                            >
                            <img :src="arrowLeft">
                        </b-button>
                    </div>
                </b-col>
                <!-- область слайдера -->
                <b-col class="col-12 col-lg-10">
                    <div class="my-3 mb-5 position-relative">
                        <div class="d-flex overflow-hidden" :id="sliderId">
                            <div class="app-slicer-container d-flex" :style="sliderContentMargin">
                                <SliderItem v-for="(item, index) in teamList"
                                    v-bind:key="index"
                                    v-bind:index="index"
                                    v-bind:item="item"
                                    v-bind:uniqId="sliderId"
                                    v-on:itemsMounted="itemsMounted"
                                ></SliderItem>
                            </div>
                        </div>
                    </div>
                </b-col>
                <!-- правый контрол -->
                <b-col cols="1" class="d-none d-lg-block">
                    <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                        <b-button 
                            class="d-flex justify-content-center align-items-center bg-transparent border-0"
                            :disabled="sliderEnd"  
                            @click="rightControl"
                        >
                            <img :src="arrowRight">
                        </b-button>
                    </div>
                </b-col>                 
            </b-row>
        </b-container>
    </section>
</template>


<script>
import utils from '../../functions/utils.js'
import { mapGetters } from 'vuex';
import SliderItem from './SliderItem.vue'
export default {
    components: {
        SliderItem
    },
    data(){
        return{
            //слайдерные дела
            sliderId: '',
            offsetX: 0, //отрицательный margin-left
            feedLength: 0,
            wrapLength: 0,
            slideWidth: 0,
            sliderEnd: false,
            realOffset: 0,
            arrowLeft: utils.imageRelToAbs('/images/icon/arrow_left.png'),
            arrowRight: utils.imageRelToAbs('/images/icon/arrow_right.png'),
            //контент
            header: [
                'Our Team',
                'Наша Команда',
            ],
            teamList: [
                {
                    name: 'Разумов Василий',
                    position: 'Founder',
                    img: utils.imageRelToAbs('/images/team/razumov.jpg'),
                },
                {
                    name: 'Зубарева Регина',
                    position: 'Chief Executive Officer',
                    img: utils.imageRelToAbs('/images/team/zubareva.jpg'),
                },
                /*
                {
                    name: 'Авдияров Никита',
                    position: 'Account Manager',
                    img: utils.imageRelToAbs('/images/team/avdiarov.jpg'),
                },
                */
                {
                    name: 'Калчу Александр',
                    position: 'Head of Account Management',
                    img: utils.imageRelToAbs('/images/team/kalchu.jpg'),
                    contact1: {
                        name: 'kalchu@zumoads.co',
                        link: 'malito:kalchu@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.8ca1fcd1f9679148',
                        link: 'skype:live:.cid.8ca1fcd1f9679148',
                    },                
                },
                {
                    name: 'Трихачева Анастасия',
                    position: 'Head of affiliates',
                    img: utils.imageRelToAbs('/images/team/trihacheva.jpg'),
                    contact1: {
                        name: 'anastasia@zumoads.co',
                        link: 'malito:anastasia@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.e8ea5cfc7d8ac31b',
                        link: 'skype:live:.cid.e8ea5cfc7d8ac31b',
                    },                
                },                    
                {
                    name: 'Шуваев Милан',
                    position: 'Head of Business Development Manager',
                    img: utils.imageRelToAbs('/images/team/nophotoman.png'),
                    contact1: {
                        name: 'Milan@zumoads.co',
                        link: 'malito:Milan@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.a9506c676c6a7c80',
                        link: 'skype:live:.cid.a9506c676c6a7c80',
                    },                
                },                                       
                {
                    name: 'Авдияров Никита',
                    position: 'Account Manager',
                    img: utils.imageRelToAbs('/images/team/avdiarov.jpg'),
                    contact1: {
                        name: 'nikitaavdiyarov@zumoads.co',
                        link: 'malito:Milan@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.9429dfaed6632ead',
                        link: 'skype:live:.cid.9429dfaed6632ead',
                    },                
                },            
                {
                    name: 'Потапов Илья',
                    position: 'Affiliate manager',
                    img: utils.imageRelToAbs('/images/team/potapov.jpg'),
                    contact1: {
                        name: 'ilya@zumoads.co',
                        link: 'malito:ilya@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.c17b6ca3c710e4ea',
                        link: 'skype:live:.cid.c17b6ca3c710e4ea',
                    },                
                },  
                {
                    name: 'Сергей Алексеевич',
                    position: 'Affiliate manager',
                    img: utils.imageRelToAbs('/images/team/alekseevich.jpg'),
                    contact1: {
                        name: 'sergey@zumoads.co',
                        link: 'malito:sergey@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.e0bbea0eeda2d767',
                        link: 'skype:live:.cid.e0bbea0eeda2d767',
                    },                
                },            
                {
                    name: 'Апсова Лада',
                    position: 'Creative Manager',
                    img: utils.imageRelToAbs('/images/team/apsova.jpg'),
                    contact1: {
                        name: 'lada@zumoads.co',
                        link: 'malito:lada@zumoads.co',
                    },
                    contact2: {
                        name: 'skype: live:.cid.hydradominatus',
                        link: 'skype:live:.cid.hydradominatus',
                    },                
                },
                {
                    name: 'Артем Сафронов',
                    position: 'Technical specialist',
                    img: utils.imageRelToAbs('/images/team/safronov.jpg'),
                    contact1: {
                        name: 'lada@zumoads.co',
                        link: 'malito:lada@zumoads.co',
                    },
                },                            
                {
                    name: 'Олег Дробышев',
                    position: 'Affiliate manager',
                    img: utils.imageRelToAbs('/images/team/drobushev.jpg'),
                    contact2: {
                        name: 'skype: live:.cid.69dfbbd6944531de',
                        link: 'skype:live:.cid.69dfbbd6944531de',
                    },
                },                    
            ]
        }
    },
    computed: {
        ...mapGetters(['SIZE_IS', 'LANG_INDEX']),
        slidePerPage: function(){
            //return (this.SIZE_IS >=5) ? 5 : 4 // ПЕРЕДЕЛАТЬ ПОД КАЖДОЕ ЗНАЧЕНИЕ ШИРИНЫ
            return (this.SIZE_IS >=5) ? 3 : 2 // ПЕРЕДЕЛАТЬ ПОД КАЖДОЕ ЗНАЧЕНИЕ ШИРИНЫ
        },
        //показывать или нет контролы в зависимости от ширины экрана
        sliderConrtols: function(){
            return (this.controlShow && this.SIZE_IS >= 6) ? true: false
        },
        //левый контрол дизейбл
        controlLeftDisable: function(){
            return (!this.offsetX) ? true : false
        },
        //правый контрол дизейбл
        controlRightDisable: function(){
            return (this.sliderEnd) ? true : false
        },
        sliderContentMargin: function(){
            return `margin-left:-${this.offsetX}px`
        },
        classControlLeft: function(){
            const classBase = 'position-absolute align-items-center '
            const classModifier = (this.fullWidth) ? 'app-scroll-control__left-full' : 'app-scroll-control__left'
            return classBase + classModifier
        },
        classControlRight: function(){
            const classBase = 'position-absolute align-items-center '
            const classModifier = (this.fullWidth) ? 'app-scroll-control__right-full' : 'app-scroll-control__right'
            return classBase + classModifier
        },
        buttonVariant: function(){
            return (this.fullWidth) ? 'light' : 'dark'
        }        
    },
    methods: {
        sliderInit: function(){
            let sliderContentWidth = 0
            const slides = document.getElementsByClassName('app-slide-'+this.sliderId)
            //ВОЗМОЖНО ЗДЕСЬ ПРИДЕТСЯ МЕНЯТЬ ОТСТУП
            //this.slideWidth = slides[0].clientWidth + 20
            this.slideWidth = slides[0].clientWidth + 16
            for (let i=0; i<slides.length; i++){
                sliderContentWidth = sliderContentWidth + this.slideWidth
            }
            this.feedLength = sliderContentWidth
            //'slider-wrap'
            this.wrapLength = document.getElementById(this.sliderId).offsetWidth
        },        
        sliderReset: function(){
            this.offsetX = 0
            this.feedLength = 0
            this.wrapLength = 0
            this.slideWidth = 0
            this.sliderEnd = false
            this.realOffset = 0      
            },
        rightControl: function(){
            const sum = this.offsetX + this.slidePerPage*this.slideWidth
            //заранее чекаем следующую перемотку
            const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2
            //def должен быть больше, чем this.wrapLength
            //если меньше, то this.offsetX вычисляется по другой формуле:  Ширина обертки - Общая ширина фида
            const def = this.feedLength - sum
            //пока сумма не превышает длину слайдер-контента, присваиваем сумму, иначе оставляем прошлое значение
            this.offsetX = (sum <= this.feedLength) ? sum : this.offsetX
            //сначала запоминаем реальное смещение, от которого потом будем минусовать
            this.realOffset = (def <= this.wrapLength) ? this.offsetX : 0
            //воизбежание пустых отступов справа
            this.offsetX = (def <= this.wrapLength) ? (this.feedLength - this.wrapLength) : this.offsetX
            //дизейблим кнопку
            this.sliderEnd = (sumNext >= this.feedLength) ? true : false
        },
        leftControl: function(){
            //если есть realOffset - минусуем от него
            const sum = (!this.realOffset) ? (this.offsetX - this.slidePerPage*this.slideWidth) : (this.realOffset - this.slidePerPage*this.slideWidth)
            //обнуляем this.realOffset
            this.realOffset = 0
            //заранее чекаем следующую перемотку
            // // const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2      //не юзалось
            this.offsetX = (sum >= 0) ? sum : this.offsetX
            //раздизейбливаем кнопку
            this.sliderEnd = false
        },
        itemsMounted: function(payload){
            if (payload === this.teamList.length-1){
                this.controlShow = true
                // this.sliderReset()
                this.sliderInit()
            }      
        }            
    },
    created: function(){
        const fullId = utils.uuidv4()
        const fullIdParts = fullId.split('-')
        this.sliderId = fullIdParts[fullIdParts.length-1]
    }
}
</script>

<style lang="sass">
.app-slicer-container
    // transition: all 0.2s
    overflow-x: scroll
    transition: all 0.6s
    scrollbar-width: none
</style>

<style scoped lang="sass">
@media (min-width: 768px)
    h1
        font-size: 64px
</style>