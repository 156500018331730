<template>
    <section :class="sectionClass">
        <figure :class="brandImageClass" :style="brandImageStyle">
            <b-img :src="this.dataObj.image"></b-img>
        </figure>
        <div v-for="(item, index) in this.dataObj.values"
            v-bind:key="index"
            v-bind:item="item"
            :class="blockClass + 'el-'+index"
        >
            <b>
                {{item.header}}
            </b>
            <i class="text-center">
                {{item.text[LANG_INDEX]}}
            </i>
        </div>
    </section>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            blockClass: 'app-rounded d-flex flex-column align-items-center justify-content-center px-1 px-xl-4 py-4 flex-shrink-0 mb-4 mb-lg-0 px-3 app-bg-violet position-relative '
        }
    },
    props: ['dataObj'],
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        //p-3 mb-0 d-flex justify-content-center align-items-center overflow-hidden app-rounded app-bg-violet
        brandImageStyle: function(){
            return (this.SIZE_IS >= 4) ? 'position: absolute; left: 0px; top: -78px; width: 100px;' : null
        },
        brandImageClass: function(){
            const classBase = 'app-cases-image d-flex justify-content-center align-items-center'
            const padding = 'p-2'
            const bg = (this.SIZE_IS >= 4) ? 'bg-white' : 'app-bg-violet'
            const rounded = (this.SIZE_IS >= 4) ? 'app-rounded-top' : 'app-rounded' 
            return `${classBase} ${bg} ${rounded} ${padding}`           
        },
        sectionClass: function(){
            const classBase = 'py-3 px-4 px-xl-5 mb-0 align-items-lg-center d-flex justify-content-between flex-wrap w-100 position-relative bg-white mb-4'
            const rounded = (this.SIZE_IS >= 4) ? 'app-rounded-triangle' : 'app-rounded'
            return `${classBase} ${rounded}`
        }
    }
}
</script>


<style scoped lang="sass">
img
    width: 100%
    height: auto
    max-width: 110px
b
    font-size: 24px
i
    font-size: 10px   
figure
    width: calc(50% - 8px)
div 
    width: calc(50% - 8px)    
@media (min-width: 768px)
    b
        font-size: 36px    
    i
        font-size: 16px

@media (min-width: 992px)            
    div
        width: calc(33.3% - 16px)  
    figure
        box-shadow: 4px -5px 8px -3px rgba(34, 60, 80, 0.3)          
@media (min-width: 1200px)            
    // div
    //     width: calc(33.3% - 16px) !important    
    img
        max-width: unset    
</style>


<!-- <style lang="sass">
.el-2
    width: 100% !important
@media (min-width: 992px)    
    .el-2
        width: calc(50% - 16px) !important        
@media (min-width: 1200px)            
</style> -->