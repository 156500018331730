<template>
    <section v-b-visible.once="animationStart" :class="animationClass">
        <!-- главная -->
        <HeaderHome v-if="componentName==='HeaderHome'"></HeaderHome>
        <MarketingAgency v-if="componentName==='MarketingAgency'"></MarketingAgency>
        <ClientPartnersCol v-if="componentName==='ClientPartnersCol'" v-bind:dataObj="dataObj"></ClientPartnersCol>
        <!-- медиабаинг -->
        <HeaderMedia v-if="componentName==='HeaderMedia'"></HeaderMedia>
        <AdvantageMedia v-if="componentName==='AdvantageMedia'"></AdvantageMedia>
        <OurResults v-if="componentName==='OurResults'"></OurResults>
        <OurSources v-if="componentName==='OurSources'"></OurSources>
        <OurGeos v-if="componentName==='OurGeos'"></OurGeos>
        <OurPartners v-if="componentName==='OurPartners'"></OurPartners>
        <!-- инапп -->
        <HeaderInapp v-if="componentName==='HeaderInapp'"></HeaderInapp>
        <OurPluses v-if="componentName==='OurPluses'"></OurPluses>
        <!-- кейсы -->
        <CasesTwoo v-if="componentName==='CasesTwoo'" v-bind:dataObj="dataObj"></CasesTwoo>
        <CasesThree v-if="componentName==='CasesThree'" v-bind:dataObj="dataObj"></CasesThree>
        <!-- команда -->
        <TeamSlider v-if="componentName==='TeamSlider'" v-bind:dataObj="dataObj"></TeamSlider>
        <!-- контакты -->
        <ContactText v-if="componentName==='ContactText'"></ContactText>
        <ContactForm v-if="componentName==='ContactForm'"></ContactForm>
        
        <FooterLine v-if="componentName==='FooterLine'"></FooterLine>
    </section>    
</template>

<script>
import HeaderHome from '../home/HeaderHome.vue'
import MarketingAgency from '../home/MarketingAgency.vue'
import ClientPartnersCol from '../home/ClientPartnersCol.vue'
//
import HeaderMedia from '../mediabuying/HeaderMedia.vue';
import AdvantageMedia from '../mediabuying/AdvantageMedia.vue';
import OurResults from '../mediabuying/OurResults.vue';
import OurSources from '../mediabuying/OurSources.vue';
//
import HeaderInapp from '../inapp/HeaderInapp.vue';
import OurGeos from '../inapp/OurGeos.vue';
import OurPluses from '../inapp/OurPluses.vue';
import OurPartners from '../inapp/OurPartners.vue'
//
import CasesTwoo from '../cases/CasesTwoo.vue'
import CasesThree from '../cases/CasesThree.vue'
//
import TeamSlider from '../team/TeamSlider.vue'
//
import ContactText from '../contact/ContactText.vue'
import ContactForm from '../contact/ContactForm.vue'

import FooterLine from '../general/FooterLine.vue';

export default {
    data(){
        return {
            isVisible: undefined,
            scrollUp: undefined
        }
    },
    components: {
        HeaderHome, // eslint-disable-line
        MarketingAgency, // eslint-disable-line
        AdvantageMedia, // eslint-disable-line
        OurResults, // eslint-disable-line
        OurSources, // eslint-disable-line
        OurGeos, // eslint-disable-line
        OurPluses, // eslint-disable-line
        ClientPartnersCol, // eslint-disable-line
        FooterLine, // eslint-disable-line
        OurPartners, // eslint-disable-line
        CasesTwoo, // eslint-disable-line
        CasesThree, // eslint-disable-line
        HeaderMedia, // eslint-disable-line
        HeaderInapp, // eslint-disable-line
        TeamSlider, // eslint-disable-line
        ContactText, // eslint-disable-line
        ContactForm, // eslint-disable-line
    },
    props: ['componentName', 'animationType', 'dataObj'],
    computed: {
        animationClass: function(){
            const defaultClassName = 'app-animate-screen-up'  
            let className = defaultClassName
            // className = (this.animationType) ? 
            //неправильная херовая логика
            // return (this.isVisible && !this.scrollUp) ? '' : className
            // return (this.isVisible || this.scrollUp) ? '' : className
            return (this.isVisible) ? '' : className
        },
        transitionStyle: function(){
            const transition = process.env.VUE_APP_BLOCK_APPEND_TRANSITION
            return `transition:${transition};`
        }
    },
    methods: {
        animationStart: function(visible){
            this.isVisible = visible
        },
    },
    watch: {
        isVisible: function(newVal, oldVal){
            this.scrollUp = (!newVal && oldVal) // ? true : false
        },
        scrollUp: function(){
            // console.log('this.scrollUp', this.scrollUp)
        }
    },
    mounted: function(){
        // console.log('componentName', this.componentName)
    }
}
</script>

<style scoped lang="sass">
section
    transition: all 0.4s ease-out
    opacity: 1
</style>


<style lang="sass">
.app-animate-screen-up
    // padding-top: 192px
    padding-top: 100vh
    opacity: 0 !important
</style>