<template>
    <div class="d-flex flex-column justify-content-center align-items-center app-rounded px-2 px-xl-4 py-3 py-lg-5 mb-3">
        <b>
            {{ item.header }}
        </b>
        <span>
            {{ item.text[LANG_INDEX] }}
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['item'],
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
b
    font-size: 56px
span
    font-size: 32px    
div
    width: 100%
    background-color: rgba(0, 0, 0, 0.34)
@media (min-width: 992px)    
    div
        width: calc(33.4% - 16px)
@media (min-width: 1366px)            
    b
        font-size: 64px
</style>