<template>
  <div id="app" style="min-height: 100vh;">
    <NavLine></NavLine>

    <div class="d-flex flex-column justify-content-between" style="min-height: 100vh;" id="findme">
      <transition name="fade">
        <router-view/>
      </transition>      

      <!-- <AnimationFrame v-bind:componentName="'FooterLine'"></AnimationFrame> -->
      <FooterLine></FooterLine>      
    </div>

    <!-- <BackgroundAnimation></BackgroundAnimation> -->
    <BgAnimation></BgAnimation>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NavLine from './components/general/NavLine.vue'
import FooterLine from './components/general/FooterLine.vue'
import BackgroundAnimation from './components/general/BackgroundAnimation.vue'
import BgAnimation from './components/general/BgAnimation.vue'
import AnimationFrame from '@/components/general/AnimationFrame.vue'
export default {
  components: {
    NavLine, // eslint-disable-line
    FooterLine, // eslint-disable-line
    BackgroundAnimation, // eslint-disable-line
    BgAnimation, // eslint-disable-line
    AnimationFrame, // eslint-disable-line
  },
  data(){
    return {
     
    }
  },
  computed: {
    ...mapGetters(['IP']),
  },
  methods: {
    ...mapActions(['IP_FETCH', 'GEO_FETCH', 'SIZE_CHECK'])
  },
  created: function(){
    this.IP_FETCH()
    this.SIZE_CHECK()
    const vueContext = this
    window.addEventListener('resize', function(){
      vueContext.SIZE_CHECK()
    })
  },
  mounted: function(){
    
  },
  watch: {
    IP: function(){
      this.GEO_FETCH(this.IP)
    }
  }
}
</script>

<style lang="sass">
.fade-leave-active
  transition: opacity .2s
.fade-enter-active
  transition: opacity 0.15s ease-in 0.2s
.fade-enter, .fade-leave-to
  opacity: 0
</style>