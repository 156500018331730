<template>
    <b-container>
        <b-row>
            <b-col>
                <section class="d-flex flex-column text-white px-4 px-lg-5 pt-5">
                    <i class="mb-3">
                        {{ textPart1[LANG_INDEX] }}
                    </i>
                    <span class="align-self-end mb-4">
                        {{ textPart2[LANG_INDEX] }}
                    </span>
                    <h1 class="align-self-start font-weight-bold font-italic mt-3">
                        {{ textPart3[LANG_INDEX] }}
                    </h1>
                    <p class="my-0 font-weight-bold font-italic align-self-end mb-3">
                        {{ textPart4[LANG_INDEX] }}
                    </p>
                </section>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            textPart1: [
                'The right decisions for the best results',
                'Агентство, которое повышает узнаваемость и прибыль вашего приложения,',
            ],
            textPart2: [
                'We work only with high-quality traffic sources that bring the maximum benefit to our clients',
                'как никто другой',
            ],
            textPart3: [
                'Media Buying',
                'Медиа-баинг',
            ],
            textPart4: [
                'Advantages of running premium sources with us',
                'Преимущества запуска премиум-источников с нами',
            ],                        
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
i, span, p
    font-size: 20px
@media (min-width: 768px)    
    i, span
        font-size: 24px
    h1
        font-size: 64px 
    p
        font-size: 32px       
@media (min-width: 992px)
    span, p
        width: 60%
</style>