<template>
    <!-- :class="wrapClass" -->
    <div class="app-rounded app-bg-violet flex-shrink-0 mb-3 d-flex justify-content-center align-items-center p-xl-2">
        <b-img :src="item"></b-img>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: ['item'],
    computed: {
        ...mapGetters(['SIZE_IS']),
        wrapClass: function(){
            const classBase = 'app-rounded app-bg-violet flex-shrink-0 mb-3 d-flex justify-content-center align-items-center'
            const padding = (this.SIZE_IS === 5) ? 'p-3' : ''
            return `${classBase} ${padding}`
        }
    }
}
</script>

<style scoped lang="sass">
div
    width: calc(50% - 8px)
    height: 92px
img
    max-width: 100px
    width: 100%

@media (min-width: 768px)        
    div
        width: calc(25% - 8px)   
@media (min-width: 992px)
    div
        width: calc(50% - 8px)
@media (min-width: 1200px)
    div
        width: calc(25% - 16px)
</style>