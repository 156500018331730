<template>
  <div>
    <!-- <HeaderHome></HeaderHome> -->
    <AnimationFrame v-bind:componentName="'HeaderHome'"></AnimationFrame>
    <!-- <MarketingAgency></MarketingAgency> -->
    <AnimationFrame v-bind:componentName="'MarketingAgency'"></AnimationFrame>
    <TeamPhoto></TeamPhoto>
    <ClientPartners></ClientPartners>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderHome from '../components/home/HeaderHome.vue'
import MarketingAgency from '../components/home/MarketingAgency.vue'
import TeamPhoto from '../components/home/TeamPhoto.vue'
import ClientPartners from '../components/home/ClientPartners.vue'
import AnimationFrame from '@/components/general/AnimationFrame.vue'
export default {
  components: {
    HeaderHome, // eslint-disable-line
    MarketingAgency, // eslint-disable-line
    TeamPhoto, // eslint-disable-line
    ClientPartners, // eslint-disable-line
    AnimationFrame // eslint-disable-line
  },
  data(){
    return {


    }
  },
  computed: {
    ...mapGetters(['LANG_INDEX'])
  },
  // name: 'HomeView',
}
</script>

<style lang="sass" scoped>
div
  color: yellow
</style>