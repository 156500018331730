<template>
        <b-container :fluid="fluid" :class="containerClass">
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-between flex-wrap mb-5 pb-3">
                        <AdvantageMediaItem v-for="(item, index) in items"
                            v-bind:key="index"
                            v-bind:item="item"
                            v-bind:index="index"
                        ></AdvantageMediaItem>
                    </div>
                </b-col>
            </b-row>
        </b-container>        
</template>


<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/utils'
import AdvantageMediaItem from './AdvantageMediaItem.vue'
export default {
    components: {
        AdvantageMediaItem
    },
    data(){
        return {
            items: [
                {
                    header: [
                    'Source selection',
                    'Выбор источников',
                    ],
                    text: [
                        'Our team will analyze market, your product and target audience',
                        'Наша команда анализирует рынок, продукт и целевую аудиторию',
                    ],
                    image: utils.imageRelToAbs('/images/icon/10.png')
                },
                {
                    header: [
                    'Creo preparation',
                    'Креативная подготовка',
                    ],
                    text: [
                        'Our design department will create effective creatives with call-to-action texts',
                        'Наш дизайнерский отдел создает эффективный креатив, включая текст call-to-action',
                    ],
                    image: utils.imageRelToAbs('/images/icon/21.png')
                },
                {
                    header: [
                    'Optimizing for High ROI',
                    'Оптимизация с высоким ROI',
                    ],
                    text: [
                        'We will be in touch 24/7 for optimizing your campaign',
                        'Мы доступны 24/7 для оптимизации ваших кампаний',
                    ],
                    image: utils.imageRelToAbs('/images/icon/24.png')
                },
                {
                    header: [
                    'Own anti-fraud system',
                    'Уникальная система Anti-Fraud',
                    ],
                    text: [
                        'Our experts will optimise your campaign in a short time in order to generate large volumes of high quality traffic',
                        'Наши партнеры оптимизируют кампании в короткие сроки и генерируют большие объемы качественного трафика',
                    ],
                    image: utils.imageRelToAbs('/images/icon/29.png')
                },
                {
                    header: [
                    'Targeting',
                    'Source selection',
                    ],
                    text: [
                        'Targeting can be according to: city, country, age, gender, interests etc.',
                        'Our team will analyze market, your product and target audience',
                    ],
                    image: utils.imageRelToAbs('/images/icon/43.png')
                },
                {
                    header: [
                    'Client Service',
                    'Source selection',
                    ],
                    text: [
                        'We have a transparent approach to work, our manager provides the highest level of service taking into account your wishes',
                        'Our team will analyze market, your product and target audience',
                    ],
                    image: utils.imageRelToAbs('/images/icon/13.png')
                },                                                                                
                

            ]
        }
    },    
    computed: {
        ...mapGetters(['SIZE_IS']),
        fluid: function(){
            return ([5,6].includes(this.SIZE_IS)) ? true : false
        },
        containerClass: function(){
            return ([5,6].includes(this.SIZE_IS)) ? 'px-0' : null
        },
    },
}
</script>
