<template>
    <div class="d-flex flex-column mb-3">
        <b-form-input v-if="item.type==='input'" size="lg" variant="secondary" v-model="value" :value="item.val" :placeholder="item.placeholder[LANG_INDEX]"></b-form-input>
        <b-form-textarea v-else size="lg" variant="secondary" v-model="value" :value="item.val" :placeholder="item.placeholder[LANG_INDEX]"></b-form-textarea>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            value: ''
        }
    },
    props: {
        item: {
            type: Object,
        },
        sending: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
    },
    watch: {
        value: function(val){
            const data = {name:this.item.inputName, val:val}
            this.$emit('dataChange', data)
        }
    },
}
</script>

<style lang="sass" scoped>
span
    user-select: none

input,
textarea
    border-radius: 14px !important   
    color: #222
    border: none !important
    outline: none !important
    box-shadow: none !important
textarea    
    height: 152px
</style>