<template>
    <b-container :fluid="fluid">
        <b-row>
            <b-col class="pl-md-0 col-md-6 col-12">
                <div class="d-flex flex-column justify-content-center h-100">
                    <OurPlusesItem v-for="(item, index) in colLeft"
                        v-bind:key="index"
                        v-bind:item="item"
                        v-bind:placement="'left'"
                    >
                    </OurPlusesItem>
                </div>
            </b-col>

            <b-col class="pl-md-0 col-md-6 col-12">
                <div class="d-flex flex-column justify-content-center h-100">
                    <OurPlusesItem v-for="(item, index) in colRight"
                        v-bind:key="index"
                        v-bind:item="item"
                        v-bind:placement="'right'"
                    >
                    </OurPlusesItem>
                </div>
            </b-col>

        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import OurPlusesItem from './OurPlusesItem.vue'
export default {
    components: {
        OurPlusesItem
    },
    data(){
        return {
            items: [
                {
                    name: [
                        'Dozens of direct sources',
                        'Десятки тысяч прямых источников'
                    ],
                    text: [
                        'We are working with more than 150 direct publishers, DSPs and media buying teams',
                        'Партнерство с более чем 150 прямыми партнерами, DSP и командами медиабаинга'
                    ]
                },
                {
                    name: [
                        'An array of mobile ad formats',
                        'Множество форматов мобильной рекламы'
                    ],
                    text: [
                        'We can work with all of the ad formats, like banner ad, native ad, video ad, gaming ad',
                        'Поддерживаются все форматы рекламы, включая баннерную рекламу, нативную рекламу, видеорекламу и рекламу в играх'
                    ]
                },
                /////////                
                {
                    name: [
                        'Anti-fraud system',
                        'Система Anti-Fraud'
                    ],
                    text: [
                        'We filter traffic using our own anti-fraud system, which monitors fraud rate, CTIT, CR, uninstall rate, RR and other necessary indicators',
                        'Мы фильтруем трафик с помощью нашей собственной системы Anti-Fraud, которая отслеживает уровень фрода, CTIT, CR, uninstall rate, RR и другие важные показатели'
                    ]
                },
                {
                    name: [
                        'High volumes',
                        'Высокие объемы'
                    ],
                    text: [
                        'Our direct publishers can generate large volumes for different verticals, like e-commerce, crypto, fintech, shopping, betting etc.',
                        'Наши прямые паблишеры могут размещать большие объемы рекламы в различных вертикалях, включая e-commerce, crypto, fintech, shopping, betting'
                    ]
                },
                {
                    name: [
                        'Try out own DSP',
                        'Try out own DSP'
                    ],
                    text: [
                        'Try our AI algorithmic platform, which will bring you premium traffic in one source',
                        'Try our AI algorithmic platform, which will bring you premium traffic in one source'
                    ]
                },                                
            ]
        }
    },
    computed: {
        ...mapGetters(['SIZE_IS']),
        colLeft: function(){
            return this.items.slice(0,2)
        },
        colRight: function(){
            return this.items.slice(2,7)
        },    
        fluid: function(){
            return ([5,6].includes(this.SIZE_IS)) ? true : false
        }    
    }
}
</script>
