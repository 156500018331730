<template>
    <b-container class="mt-5">
        <b-row>
            <b-col>
                <div class="d-flex flex-column bg-white app-rounded px-3 pt-3 pb-2 pt-xl-4 px-xl-5 pb-xl-4">
                    <b class="font-weight-bold app-text-violet text-center mb-3">
                        {{ header[LANG_INDEX] }}
                    </b>
                    <!-- список -->
                    <div class="d-flex justify-content-between flex-wrap">
                        <OurPartnersItem v-for="(item, index) in items"
                            v-bind:key="index"
                            v-bind:item="item"
                        >
                        </OurPartnersItem>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import utils from '../../functions/utils.js' // eslint-disable-line
import OurPartnersItem from './OurPartnersItem.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        OurPartnersItem
    },
    data(){
        return {
            header: [
                'Our partners',
                'Наши партнеры',
            ],
            items: [
                utils.imageRelToAbs('/images/partners/shareit.png'),
                utils.imageRelToAbs('/images/partners/nativex.svg'),
                utils.imageRelToAbs('/images/partners/yeahmobi.png'),
                utils.imageRelToAbs('/images/partners/pokkt.png'),
                utils.imageRelToAbs('/images/partners/inmobi.png'),
                // utils.imageRelToAbs('/images/partners/ironsource.png'),
                utils.imageRelToAbs('/images/partners/mobyoung.png'),
                utils.imageRelToAbs('/images/partners/adtiming.png'),
                utils.imageRelToAbs('/images/partners/affle.png'),
            ]
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        heaederStyle: function(){
            return (this.SIZE_IS <= 2) ? 'order:-1; width:100%;' : null
        }
    }
}
</script>

<style scoped lang="sass">
b
    font-size: 24px
@media (min-width: 768px)    
    b    
        font-size: 36px
</style>
