<template>
    <section class="bg-white app-rounded-top position-relative mt-5 py-3 py-sm-2">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center app-text-violet">
                        <!-- ссылки меню -->
                         <div class="d-flex flex-row-reverse flex-md-row justify-content-between flex-wrap flex-grow-1 pr-md-3 pr-lg-5">
                            <span v-for="(item, index) in NAV_ITEMS"
                                v-bind:key="index"
                                class="my-1 my-md-0 flex-shrink-0"
                            >
                                <b-link :to="item.href" class="app-text-violet font-weight-bold ">
                                    {{ item.anchor[LANG_INDEX] }}
                                </b-link>
                            </span>
                         </div>
                        <!-- правая часть -->
                        <div class="d-flex flex-md-column flex-lg-row align-items-center">
                            <!-- футерное лого -->
                            <b-link :to="'/'" class="mr-3 mr-md-0 mr-lg-5">
                                <img :src="logo">
                            </b-link>
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <div class="d-flex justify-content-center">
                                    <div v-for="(item, index) in socialMedia"
                                        v-bind:key="index"
                                        class="flex-shrink-0"
                                    > 
                                        <b-link target="_blank" :href="item.link" class="mx-2">
                                            <img :src="item.image">
                                        </b-link>
                                    </div>
                                </div>
                                <b-link :href="EMAIL.link" class="app-text-violet font-weight-bold pt-1">
                                    {{ EMAIL.name }}
                                </b-link>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/utils'
export default {
    data(){
        return {
            logo: utils.imageRelToAbs('/images/icon/logo_footer.png'),
            contactUsText: [
                'Contact Us',
                'Связаться с нами',
            ],
            socialMedia: [
                {
                    name: 'instagram',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/instagram_small.png'),
                },
                {
                    name: 'telegram',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/telegram_small.png'),
                },
                {
                    name: 'tiktok',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/tiktok_small.png'),
                },                        
                {
                    name: 'linkedin',
                    link: '#',
                    image: utils.imageRelToAbs('/images/icon/linkedin_small.png'),
                },            
            ],            
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX', 'NAV_ITEMS', 'SOCIAL_MEDIA', 'EMAIL']),        
    },    
}
</script>

<style lang="sass">
.app-footer
    a
        color: #F2F2F2
.app-footer-logo__name        
    font-size: 18px
.app-footer-logo__slug
    font-size: 12px
.app-footer-contact
    font-size: 12px    
@media (min-width: 768px)    
    .app-footer-logo__name        
        font-size: 24px
    .app-footer-logo__slug
        font-size: 16px
    .app-footer-contact
        font-size: 16px            
</style>

<style scoped lang="sass">
span
    width: 50%
@media (min-width: 992px)    
    span
        width: unset
</style>