<template>
    <b-container>
        <b-row>
            <b-col>
                <section class="d-flex flex-column text-white px-4 px-lg-5 pt-5">
                    <i class="align-self-end mb-3 mb-lg-4">
                        {{ textPart1[LANG_INDEX] }}
                    </i>
                    <div class="d-flex flex-column flex-lg-row mb-3 mb-lg-5">
                        <span class="mr-lg-3">
                            {{ textPart2[LANG_INDEX] }}
                        </span>
                        <h1 class="align-self-lg-right font-weight-bold text-right mt-3 my-sm-4">
                            {{ textPart3[LANG_INDEX] }}
                        </h1>
                    </div>
                    <b class="mb-3 mb-xl-5 font-italic font-weight-bold">
                        {{ textPart4[LANG_INDEX] }}
                    </b>
                </section>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            textPart1: [
                'The best decisions to promote your app',
                'Лучшее решение для продвижения приложений',
            ],
            textPart2: [
                'Increase the number of app users and generate additional income with us. Optimize your advertising budget and explore new perfomance sources',
                'Увеличивайте базу пользователей и получайте дополнительный доход вместе с нами',
            ],
            textPart3: [
                'In-app department',
                'Отдел Ин-апп',
            ],                        
            textPart4: [
                'Explore mew traffic sources with us and optimize your spending on your existing ones',
                'Оптимизируйте свой рекламный бюджет и используйте новые, эффективные источники',
            ],                                    
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
i, b
    font-size: 24px
span
    font-size: 28px        
@media (min-width: 768px)    
    span
        font-size: 32px
    h1
        font-size: 64px
    b
        font-size: 36px
</style>