<template>
    <section :class="sectionClass">
        <figure :class="brandImageClass" :style="brandImageStyle">
            <b-img :src="this.dataObj.image"></b-img>
        </figure>
        <div v-for="(item, index) in this.dataObj.values"
            v-bind:key="index"
            v-bind:item="item"
            :class="blockClass + 'element-'+index"
        >
            <b>
                {{item.header}}
            </b>
            <i class="text-center">
                {{item.text[LANG_INDEX]}}
            </i>
        </div>
    </section>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            blockClass: 'app-rounded d-flex flex-column align-items-center justify-content-center px-1 px-xl-4 py-4 flex-shrink-0 mb-lg-0 px-3 app-bg-violet position-relative '
        }
    },
    props: ['dataObj'],
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        // process.env.VUE_APP_BLOCK_APPEND_TRANSITION
        brandImageStyle: function(){
            const transition = process.env.VUE_APP_BLOCK_APPEND_TRANSITION
            return (this.SIZE_IS >= 4) ? `position: absolute; left: 0px; top: -78px; width: 100px; transition:${transition}` : null
        },
        brandImageClass: function(){
            const classBase = 'app-cases-image d-flex justify-content-center align-items-center'
            const padding = 'p-2'
            const bg = (this.SIZE_IS >= 4) ? 'bg-white' : 'app-bg-violet'
            const rounded = (this.SIZE_IS >= 4) ? 'app-rounded-top' : 'app-rounded' 
            return `${classBase} ${bg} ${rounded} ${padding}`           
        },
        sectionClass: function(){
            const classBase = 'py-3 px-4 px-xl-5 align-items-lg-center d-flex justify-content-between flex-wrap w-100 position-relative bg-white mb-4'
            const rounded = (this.SIZE_IS >= 4) ? 'app-rounded-triangle' : 'app-rounded'
            return `${classBase} ${rounded}`
        }
    }
}
</script>


<style scoped lang="sass">
img
    width: 100%
    height: auto
    max-width: 110px
b
    font-size: 24px
i
    font-size: 10px   
figure
    width: calc(50% - 8px)
    height: 116px
div 
    width: calc(50% - 8px)    
    height: 116px
@media (min-width: 768px)
    b
        font-size: 36px    
    i
        font-size: 16px
@media (min-width: 992px)            
    figure
        height: unset
        box-shadow: 4px -5px 8px -3px rgba(34, 60, 80, 0.3)

@media (min-width: 1200px)            
    div
        width: calc(50% - 16px) !important    
    img
        max-width: unset    
    figure
        height: unset
</style>


<style lang="sass">
.app-animate-screen-up
    .app-cases-image
        box-shadow: none !important
    
.element-1
    width: 100% !important
@media (min-width: 992px)    
    .element-1
        width: calc(50% - 16px) !important        
@media (min-width: 1200px)            
</style>