<template>
    <section class="bg-white app-rounded-bottom py-3">
        <b-container class="px-3 pl-xl-5">
            <b-row>
                <b-col>
                    <div class="d-flex align-items-center justify-content-between">
                        <!-- Лого -->
                        <b-link to="/" class="ml-xl-5">
                            <b-img :src="logoHeader"></b-img>
                        </b-link>
                        <!-- Десктопное меню -->
                         <div class="d-none d-lg-flex justify-content-between align-items-center pr-xl-5">
                            <NavLineDesktopItem v-for="(item, index) in NAV_ITEMS"
                                v-bind:key="index"
                                v-bind:item="item"
                            ></NavLineDesktopItem>
                         </div>
                        <!-- Кнопка бургер -->
                        <b-navbar-toggle target="nav-collapse" class="d-lg-none">
                            <template #default="{ expanded }">
                                <b-icon v-if="expanded" icon="x" class="app-text-violet h1"></b-icon>
                                <b-icon v-else icon="list" class="app-text-violet h1"></b-icon>
                            </template>                
                        </b-navbar-toggle>    

                        <!-- Меню на мобилке -->
                        <b-sidebar class="d-flex flex-column" no-header shadow id="nav-collapse">
                            <div class="d-flex flex-column justify-content-center h-100 pl-3" style="background: #452f77;">
                                <NavLineDesktopItem
                                    v-for="(item, index) in NAV_ITEMS"
                                    v-bind:key="index"
                                    v-bind:item="item"
                                    v-bind:activeClass="'text-danger'"
                                    v-bind:isFooter="true"
                                    v-bind:isMobile="true"
                                ></NavLineDesktopItem>
                            </div>
                            <!-- Переключение языков, пока стопнем -->
                            <!-- <b-nav-item-dropdown :text="langDropdownLabel[LANG_INDEX]" right class="app-dropdown-lang">
                                <b-dropdown-item href="#" @click.prevent="LANG_CHANGE(0)">EN</b-dropdown-item>
                                <b-dropdown-item href="#" @click.prevent="LANG_CHANGE(1)">RU</b-dropdown-item>
                            </b-nav-item-dropdown>                 -->
                        </b-sidebar>                     
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/utils'
import NavLineDesktopItem from './NavLineDesktopItem.vue'
export default {
    components: {
        NavLineDesktopItem 
    },
    data(){
        return {
            // logoHeader: require('../images/general/logo_header.png')
        }
    },
    computed: {
        ...mapGetters(['NAV_ITEMS']),
        logoHeader: function(){
            return utils.imageRelToAbs('/images/icon/logo_header.png')
        }
    }
}
</script>
