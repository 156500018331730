<template>
    <b-container>
        <b-row>
            <b-col>
                <h3 class="font-weight-bold text-center mt-5 mb-4">
                    {{ header[LANG_INDEX] }}
                </h3>
                <div class="d-flex justify-content-between flex-wrap pt-0 pb-3 px-4 px-xl-5">
                    <ResultMediaItem v-for="(item, index) in items"
                    v-bind:key="index"
                    v-bind:item="item"
                    ></ResultMediaItem>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ResultMediaItem from './OurResultsItem.vue';
export default {
    components: {
        ResultMediaItem
    },
    data(){
        return {
            isVisible: undefined,
            header: [
                'Our results',
                'Результаты',
            ],
            items: [
                {
                    header: '>15',
                    text: ['traffic sources','Источников трафика']
                },
                {
                    header: '>100m',
                    text: ['installations','Установок']
                },
                {
                    header: '150+',
                    text: ['partners','Партнеров']
                },                                
            ]
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
        animationClass: function(){
            return (this.isVisible) ? '' : 'pt-5'
        }
    },
    methods: {
        animationStart: function(visible){
            this.isVisible = visible
        },
    }
}
</script>

<style scoped lang="sass">
h3
    font-size: 36px
</style>