import { render, staticRenderFns } from "./OurResults.vue?vue&type=template&id=887ed9c0&scoped=true"
import script from "./OurResults.vue?vue&type=script&lang=js"
export * from "./OurResults.vue?vue&type=script&lang=js"
import style0 from "./OurResults.vue?vue&type=style&index=0&id=887ed9c0&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "887ed9c0",
  null
  
)

export default component.exports