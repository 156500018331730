import { render, staticRenderFns } from "./AdvantageMediaItem.vue?vue&type=template&id=08937a96&scoped=true"
import script from "./AdvantageMediaItem.vue?vue&type=script&lang=js"
export * from "./AdvantageMediaItem.vue?vue&type=script&lang=js"
import style0 from "./AdvantageMediaItem.vue?vue&type=style&index=0&id=08937a96&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08937a96",
  null
  
)

export default component.exports