<template>
    <div :class="cardClass">
        <b-aspect :aspect="'1:1'">
            <figure class="m-0 p-0 d-flex justify-content-center align-items-center overflow-hidden app-rounded">
                <img :src="item.img" class="h-100 flex-shrink-0">
            </figure>
        </b-aspect>
        <h3 class="font-italic font-weight-bold my-1">
            {{ item.name }}
        </h3>
        <span>
            {{ item.position }}
        </span>
        <a v-if="item.contact1" :href="item.contact1.link" class="app-text-violet">
            {{ item.contact1.name }}
        </a>
        <a v-if="item.contact2" :href="item.contact2.link" class="app-text-violet">
            {{ item.contact2.name }}
        </a>        
    </div>
</template>


<script>
export default {
    props: ['item', 'index', 'uniqId'],
    computed: {
        cardClass: function(){
            const classBase = 'app-team-card d-flex flex-column mx-2 app-rounded bg-white app-text-violet p-3 flex-shrink-0'
            return `${classBase} app-slide-${this.uniqId}`
        }
    },
    mounted: function(){
        this.$emit('itemsMounted', this.index)
    },    
}
</script>

<style lang="sass">
.app-team-card
    width: 290px
@media (min-width: 1200px)
    .app-team-card
        width: 290px
</style>

<style scoped lang="sass">
a
    font-size: 0.94rem
</style>