<template>
    <div class="d-flex flex-column flex-shrink-0 mt-3">
        <span class="d-flex justify-content-center align-items-center app-rounded app-bg-violet text-white p-3">
            <b v-if="!item.image">
                {{ item.value }}
            </b>
            <b-img v-else :src="item.image"></b-img>
        </span>
        <i class="app-text-violet text-center">
            {{ item.name[LANG_INDEX] }}
        </i>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: ['item'],
    computed: {
        ...mapGetters(['LANG_INDEX'])
    }
}
</script>

<style scoped lang="sass">
img
    width: 100%
    height: auto
    max-width: 164px
div
    width: 100%
span    
    height: 157px
i    
    font-size: 20px
b
    font-size: 68px    
@media (min-width: 768px)    
    div
        width: calc(33% - 16px)
@media (min-width: 1366px)
    div
        width: calc(33% - 24px)        
</style>