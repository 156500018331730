export default{
    imageRelToAbs: function(path){
        //path - for excample - '/images/team/all.png
        const urlLocal = 'http://localhost'
        const urlExternal = (document.location.href.match(/vh198170/i)) ? 'https://vh198170.eurodir.ru' : 'https://zumoads.com'
        const urlBase =  (process.env.VUE_APP_LOCAL_PHP === '0') ? urlExternal : urlLocal
        return `${urlBase}${path}`
    },
    scriptRelToAbs: function(path){
        const urlLocal = 'http://localhost'
        const urlExternal = (document.location.href.match(/vh198170/i)) ? 'https://vh198170.eurodir.ru' : 'https://zumoads.com'
        const urlBase =  (process.env.VUE_APP_LOCAL_PHP === '0') ? urlExternal : urlLocal
        return `${urlBase}${path}`
    },
    uuidv4: function () {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    },    
}