<template>
    <b-container class="mt-5">
        <b-row>
            <b-col>
                <div class="d-flex flex-column bg-white app-rounded px-3 pt-3 pb-2 pt-xl-5 px-xl-5 pb-xl-4">
                    <!-- первый ряд с заголовком -->
                    <div class="d-flex justify-content-between flex-wrap">
                        <OurSourcesItem v-bind:item="items[0]"></OurSourcesItem>
                        <!-- заголовок -->
                        <b class="app-text-violet font-weight-bold d-flex justify-content-center align-items-center mb-3 mb-lg-0 pb-3" :style="heaederStyle">
                            {{header[LANG_INDEX]}}
                        </b>
                        <OurSourcesItem v-bind:item="items[1]"></OurSourcesItem>
                    </div>
                    <!-- Остальные -->
                    <div class="d-flex justify-content-between flex-wrap">
                        <OurSourcesItem v-for="(item, index) in itemsTrimmed"
                            v-bind:key="index"
                            v-bind:item="item"
                        >
                        </OurSourcesItem>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import utils from '../../functions/utils.js' // eslint-disable-line
import OurSourcesItem from './OurSourcesItem.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        OurSourcesItem
    },
    data(){
        return {
            header: [
                'Our sources',
                'Наши источники',
            ],
            items: [
                utils.imageRelToAbs('/images/sources/vungle.png'),
                utils.imageRelToAbs('/images/sources/yandex.png'),
                utils.imageRelToAbs('/images/sources/mytarget.png'),
                utils.imageRelToAbs('/images/sources/mintegral.png'),
                utils.imageRelToAbs('/images/sources/tiktok.png'),
                utils.imageRelToAbs('/images/sources/ironsource.png'),
                utils.imageRelToAbs('/images/sources/unity.png'),
                utils.imageRelToAbs('/images/sources/googleads.png'),
                utils.imageRelToAbs('/images/sources/telegram.png'),
                utils.imageRelToAbs('/images/sources/vk.png'),
            ]
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX', 'SIZE_IS']),
        itemsTrimmed: function(){
            return this.items.slice(1, 10)
        },
        heaederStyle: function(){
            return (this.SIZE_IS <= 2) ? 'order:-1; width:100%;' : null
        },
    },
    methods: {
    }
}
</script>

<style scoped lang="sass">
b
    font-size: 24px
@media (min-width: 768px)    
    b    
        font-size: 28px
@media (min-width: 992px)    
    b    
        font-size: 36px        
</style>
